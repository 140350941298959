<template>
    <a-form
        name="sDash_validation-form"
        ref="formRef"
        :model="formState.generalInfo"
        :rules="rules"
        layout="vertical"
    >
        <a-row :gutter="25" v-if="!formState.hideFormCreateChildrenEvent || !childrenEvents.data.length">
            <div class="card w-100">
            <!--{{JSON.stringify(formState)}}-->
                <a-col :xxl="8" :lg="10" :md="12" :xs="23" style="padding-left: 0" class="box-event">
                    <h3 class="form-font title">Datos específicos por evento</h3>
                </a-col>
                <!--INICIO::FORMULARIO hijos-->
                <FormValidationWrap>
                    <VerticalFormStyleWrap>
                        <a-row :gutter="30">
                            <a-col :md="24" :xs="24">
                                <a-form-item ref="title" name="title" label="Nombre del evento *" class="form-font mx-3">
                                    <a-input v-model:value="formState.generalInfo.title" v-on:change="handleChangeTitle($event)" placeholder="" class="input-field"/>
                                </a-form-item>
                            </a-col>
                        </a-row>
                        <a-row :gutter="30">
                            <a-col :md="12" :xs="24">
                                <a-form-item ref="event_start_date" label="Fecha de inicio del evento *" name="event_start_date" class="form-font">
                                  <a-input
                                    :min='eventForm.generalInfo.event_date'
                                    :max='eventForm.generalInfo.end_date'
                                    placeholder=""
                                    type="date"
                                    v-model:value="formState.generalInfo.event_start_date"
                                    class="input-field" />
                                </a-form-item>
                            </a-col>
                            <a-col :md="12" :xs="24">
                                <a-form-item ref="event_end_date" label="Fecha de finalización del evento *" name="event_end_date" class="form-font">
                                  <a-input
                                    :min='eventForm.generalInfo.event_date'
                                    :max='eventForm.generalInfo.end_date'
                                    placeholder=""
                                    type="date"
                                    v-model:value="formState.generalInfo.event_end_date"
                                    class="input-field" />
                                </a-form-item>
                            </a-col>
                            <a-col :md="12" :xs="24">
                                <a-form-item ref="event_start_hour" label="Hora de inicio del evento *" name="event_start_hour" class="form-font">
                                    <a-time-picker :defaultValue="timeEvent.event_hour_default" format="HH:mm" @change="(time, timeString) => { formState.generalInfo.event_start_hour = timeString; }" placeholder="Seleccionar hora" class="input-field" />
                                </a-form-item>
                            </a-col>
                            <a-col :md="12" :xs="24">
                                <a-form-item ref="event_end_hour" label="Hora de finalización del evento *" name="event_end_hour" class="form-font">
                                    <a-time-picker :defaultValue="timeEvent.event_final_hour_default" format="HH:mm" @change="(time, timeString) => { formState.generalInfo.event_end_hour = timeString; }" placeholder="Seleccionar hora" class="input-field" />
                                </a-form-item>
                            </a-col>
                        </a-row>
                        <a-row :gutter="30">
                        <a-col :md="12" :xs="24">
                            <a-form-item ref="start_register_date" label="Fecha de inicio del registro *" name="start_register_date" class="form-font">
                              <a-input
                                :disabled="!formState.generalInfo.event_start_date"
                                :min='today'
                                :max='formState.generalInfo.event_end_date'
                                placeholder=""
                                type="date"
                                v-model:value="formState.generalInfo.start_register_date"
                                class="input-field" />
                            </a-form-item>
                        </a-col>
                          <a-col :md="12" :xs="24">
                              <a-form-item ref="end_register_date" label="Fecha de finalización del registro *" name="end_register_date" class="form-font">
                                <a-input
                                  :disabled="!formState.generalInfo.start_register_date"
                                  :min='formState.generalInfo.start_register_date'
                                  :max='formState.generalInfo.event_start_date'
                                  placeholder=""
                                  type="date"
                                  v-model:value="formState.generalInfo.end_register_date"
                                  class="input-field" />
                            </a-form-item>
                        </a-col>
                        </a-row>
                    </VerticalFormStyleWrap>
                </FormValidationWrap>
            </div>
        </a-row>
        <sede-form
          v-if="!formState.hideFormCreateChildrenEvent || !childrenEvents.data.length"
          :sedesForm="formState.sedes"
          :eventForm="formState"
          :sendForm="sendForm.action"
          :isEdit='isEdit'
          :isFormOnEdit='formState.isFormOnEdit'
          :headquartersFromApi='headquartersFromApi'
          v-on:getFormValuesSedes="sendValuesToValidate"
        />
        <aimed-at-child-event
          :childrenEvents="childrenEvents.data"
          :eventForm="formState"
          :formResponseParent="formParent.response"
          :isFormOnEdit='formState.isFormOnEdit'
          :isEdit='isEdit'
          v-on:getFormValues="getValidationForms"
          v-on:saveDataNewEvent="addNewChildEvent"
          v-on:hideFormsCreate="showFormByEmit"
          @delete:subEvent="handleDeleteSubEvent($event)"
          @update:subEvent="editSubEvent($event)"
          @delete:subEventWithOutAsk="deleteSubEvent($event)"
         />
    </a-form>
    <!--INICIO::MODAL CONFIRMACION ELIMINAR HIJO-->
    <sdModal
      type="primary"
      :width="300"
      :visible="formState.eventToDelete!=null"
      :onCancel="handleCancelDelete"
      class="title-bold-normal">
      <a-row :gutter="25" class="mt-4"  style="text-align: center;">
        <a-col :xxl="24" :lg="24" :md="24" :xs="24" >
          <h3 class="title-normal">
            ¿Estas seguro de eliminar este evento hijo?
          </h3>
        </a-col>
      </a-row>
      <a-row :gutter="25"  style="text-align: center; margin-top: 2rem;">
        <a-col :xxl="24" :lg="24" :md="24" :xs="24" >
          <button class="btn-outlined-exit" v-on:click="handleCancelDelete">
            <span class="title-normal"> Cancelar </span>
          </button>
          <button class="btn-fill-add new-sede" v-on:click="deleteSubEvent(formState.eventToDelete, true)">
            <span class="title-normal"> Aceptar </span>
          </button>
        </a-col>
      </a-row>
    </sdModal>
    <!--FIN::MODAL CONFIRMACION ELIMINAR HIJO-->
</template>
<script>

import { FormValidationWrap, VerticalFormStyleWrap } from '../Style';
import { reactive, ref, defineAsyncComponent } from 'vue';
import moment from 'moment';
import locale from 'ant-design-vue/es/date-picker/locale/es_ES';
import {generateUUID} from '@/utility/utility.js';
import * as questionTypes from '@/constants/QuestionTypes.js';
//import { getDataByCP } from '@/services/sepomex';

const SedeForm = defineAsyncComponent(() => import('./SedesChildEvent.vue'))
const AimedAtChildEvent = defineAsyncComponent(() => import('./AimedAtChildEvent.vue'))

const ChildEvent = {
  name: 'ChildEvent',
  components: {
    FormValidationWrap,
    VerticalFormStyleWrap,
    SedeForm,
    AimedAtChildEvent,
  },

  props: {
    eventForm: null,
    subevents: [],
    sendFormToParent: Boolean,
    isEdit: Boolean,
    headquartersFromApi: []
  },
  watch: {
    sendFormToParent: function () {
      this.sendStatusValidationForm();
    },
  },
  emits: ['getFormValuesToParent'],
  async setup(props, {emit}) {
    const formRef = ref();

    const rowID = `R${generateUUID()}`
    const colID = `C${generateUUID()}`

    const childrenEvents = reactive({
      data: props.subevents
    })

    var formParent = reactive({
      response: null
    })
    const sendForm = reactive({
      action: false
    })

    let formState = reactive({
        id: null,
        headquartersDeleted: [],
        showConfmirmDeleteSubEventModal :false,
        eventToDelete: null,
        actualIdEventEdit: null,
        isFormOnEdit: false,
        hideFormCreateChildrenEvent: !!props.subevents.length,
        admin_can_register: false,
        edu_can_register: false,
        student_can_register: false,
        citizen_can_register: false,
        all_areas: false,
        all_work_areas: false,
        administrative_area_ids: [],
        work_center_ids: [],
        activities: false,
        maximum_assistance: null,
        generalInfo: {
            title: '',
            event_start_date: '',
            event_end_date: '',
            event_start_hour: '',
            event_end_hour: '',
            start_register_date: '',
            end_register_date: '',
        },
        sedes: [],
        files: [],
        registration: {
            director_register: 0,
            only_admin_register: 0,
            responsible_id: null,
            info_visible: 1,
            email: '',
            telephone: '',
            assistants: false,
            attendance_record_responsible_id: null
        },
        dynamicForm: {
            updatedQuestions: [],
            sections: [
            {
                id: `S${generateUUID()}`,
                title: '',
                description: '',
                questions: [
                {
                    id: `Q${generateUUID()}`,
                    uuid: '',
                    validations: {
                    required: false,
                    dataType: 'string',
                    characterType: 'text',
                    validationType: 'limits',
                    },
                    maxFileSize: 10,
                    maxFiles: 1,
                    hasDescription: false,
                    hasValidations: true,
                    title: '',
                    description: '',
                    type: questionTypes.shortAnswer,
                    goto: false,
                    options: [
                    { id: `O${generateUUID()}`, content: '', label: '', goto: 'next' },
                    ],
                    matrixOptions: {
                    rows: [
                        { id: rowID, content: '' },
                    ],
                    cols: [
                        { id: colID, content: '' },
                    ],
                    },
                    matrixValidations: {
                    [`T_${colID}_${rowID}`] :{
                        dataType: 'text',
                        validationType: 'limits',
                        min: '',
                        max: '',
                        format: 'email',
                    }
                    },
                    layout: 'vertical',

                },
                ],
            },

            ],
            layout: 'vertical'
        },
    })

    let timeEvent = reactive({
      event_hour_default: formState.generalInfo.event_start_hour ? moment(formState.generalInfo.event_start_hour, 'HH:mm:ss') : null,
      event_final_hour_default: formState.generalInfo.event_end_hour ? moment(formState.generalInfo.event_end_hour, 'HH:mm:ss') : null,
      dateRangePickerDefaultValue: formState.generalInfo.event_start_date && formState.generalInfo.event_end_date
        ? [moment(formState.generalInfo.event_start_date), moment(formState.generalInfo.event_end_date)]
        : [],
    })

    const rules = {
      title: [
        {
          required: true,
          message: 'Este campo es requerido',
          type: 'string',
        },
      ],
      event_start_date: [
        {
          required: true,
          message: 'Este campo es requerido',
          type: 'string',
        },
      ],
      event_end_date: [
        {
          required: true,
          message: 'Este campo es requerido',
          type: 'string',
        },
      ],
      event_start_hour: [
        {
          required: true,
          message: 'Este campo es requerido',
          type: 'string',
        },
      ],
      event_end_hour: [
        {
          required: true,
          message: 'Este campo es requerido',
          type: 'string',
        },
      ],
      start_register_date: [
        {
          required: true,
          message: 'Este campo es requerido',
          type: 'string',
        },
      ],
      end_register_date: [
        {
          required:  true,
          message: 'Este campo es requerido',
          type: 'string',
        },
      ],
    };

     const onChangeRangeDatePicker = (date, dateString) => {
      formState.generalInfo.start_register_date = dateString[0];
      formState.generalInfo.end_register_date = dateString[1];
    }

    const handleChangeTitle = (event) => {
      formState.dynamicForm.sections[0].title = event.target.value;
    }

    const sendValuesToValidate = (values) => {
      formParent.response = values;
    }

    const getValidationForms = () => {
      formRef.value
        .validate()
        .then(() => {
          sendValuesToValidate({ is_ok: true, typeForm: 'generalInfo' })
        })
        .catch(() => {
          sendValuesToValidate({ is_ok: false, typeForm: 'generalInfo' })
        });
      sendForm.action = !sendForm.action;
    }

    const addNewChildEvent = (eventChild) => {
      props.subevents.push(eventChild);
    }

    const showFormByEmit = (value) => {
      formState.hideFormCreateChildrenEvent = value;
      formRef.value.resetFields();
      if(value){
        formState.isFormOnEdit = false;
        formState.actualIdEventEdit = null;
        formState.eventToDelete = null;
      }
    }

    const sendStatusValidationForm = () => {
      emit(
        'getFormValuesToParent',
        {
          is_ok: !!(props.eventForm.generalInfo.grouped_events && props.eventForm.subevents.length),
          typeForm: 'subevents'
        }
      )
    }
    const disabledDate = (current) => {
      const endDate = formState.generalInfo.event_start_date
      const startDate = props.eventForm.generalInfo.registration_date
      if (!current || !endDate) {
        return false
      }
      //return current.valueOf() <= (Date.parse(Date(startDate)) - 86400000) || current.valueOf() > (Date.parse(endDate) + 86400000)
      return current < moment(startDate, 'DD-MM-YYYY') || current.valueOf() > (Date.parse(endDate) + 86400000)
    };

    const deleteSubEvent = (eventToDelete, addToSubEventsDeleted=false) => {
      if(eventToDelete==null){
        return
      }
      if(addToSubEventsDeleted){
        const eventDeletedID = props.subevents.splice(eventToDelete,1)[0].id;
        resetForm();
        if(eventDeletedID!=null){
            props.eventForm.subeventsDeleted.push(eventDeletedID)
        }
        handleCancelDelete();
      }else{
        props.subevents.splice(eventToDelete,1);
      }
    }

   const handleCancelDelete=()=>{
     formState.eventToDelete = null
    }
    const handleDeleteSubEvent = (index)=>{
      formState.eventToDelete = index

    }
    const resetForm=()=>{
      formState.id = null
      formState.showConfmirmDeleteSubEventModal =false
      formState.eventToDelete=null
      formState.actualIdEventEdit= null
      formState.isFormOnEdit= false
      formState.hideFormCreateChildrenEvent= !!props.subevents.length
      formState.admin_can_register= false
      formState.edu_can_register= false
      formState.student_can_register= false
      formState.citizen_can_register= false
      formState.all_areas= false
      formState.all_work_areas= false
      formState.administrative_area_ids= []
      formState.work_center_ids=[]
      formState.activities= false
      formState.maximum_assistance= null
      formState.generalInfo={
        title: '',
          event_start_date: '',
          event_end_date: '',
          event_start_hour: '',
          event_end_hour: '',
          start_register_date: '',
          end_register_date: '',
      }
      formState.sedes= []
      formState.files= []
      formState.registration={
        director_register: 0,
          only_admin_register: 0,
          responsible_id: null,
          info_visible: 1,
          email: '',
          telephone: '',
          assistants: false,
          attendance_record_responsible_id: null
      }
      formState.dynamicForm= {
        updatedQuestions: [],
          sections: [
          {
            id: `S${generateUUID()}`,
            title: '',
            description: '',
            questions: [
              {
                id: `Q${generateUUID()}`,
                uuid: '',
                validations: {
                  required: false,
                  dataType: 'string',
                  characterType: 'text',
                  validationType: 'limits',
                },
                maxFileSize: 10,
                maxFiles: 1,
                hasDescription: false,
                hasValidations: true,
                title: '',
                description: '',
                type: questionTypes.shortAnswer,
                goto: false,
                options: [
                  { id: `O${generateUUID()}`, content: '', label: '', goto: 'next' },
                ],
                matrixOptions: {
                  rows: [
                    { id: rowID, content: '' },
                  ],
                  cols: [
                    { id: colID, content: '' },
                  ],
                },
                matrixValidations: {
                  [`T_${colID}_${rowID}`] :{
                    dataType: 'text',
                    validationType: 'limits',
                    min: '',
                    max: '',
                    format: 'email',
                  }
                },
                layout: 'vertical',

              },
            ],
          },

        ],
          layout: 'vertical'
      }
      timeEvent.event_hour_default = null;
      timeEvent.event_final_hour_default = null;
      timeEvent.dateRangePickerDefaultValue = [];
      formRef.value.resetFields();
    }
    const editSubEvent = (event) => {
      formState.id = event.id || null,
      formState.actualIdEventEdit = event.indexToEdit;
      formState.isFormOnEdit = true;
      formState.admin_can_register = event.admin_can_register;
      formState.edu_can_register = event.edu_can_register;
      formState.student_can_register = event.student_can_register
      formState.citizen_can_register = event.citizen_can_register
      formState.all_areas = event.all_areas;
      formState.all_work_areas = event.all_work_areas;
      formState.administrative_area_ids = event.administrative_area_ids;
      formState.work_center_ids = event.work_center_ids;
      formState.activities = event.activities;
      formState.maximum_assistance = event.maximum_assistance;
      formState.generalInfo = {
        title: event.title,
        event_start_date: event.event_start_date,
        event_end_date: event.event_end_date,
        event_start_hour: event.event_start_hour,
        event_end_hour: event.event_end_hour,
        start_register_date: event.start_register_date,
        end_register_date: event.end_register_date,
      },
      formState.sedes = event.headquarters;
      formState.files = event.files;
      formState.registration = {
        director_register: event.director_register,
        only_admin_register: event.only_admin_register,
        responsible_id: event.responsible_id,
        info_visible: event.info_visible,
        email: event.email,
        telephone: event.telephone,
        assistants: event.assistants,
        attendance_record_responsible_id: event.attendance_record_responsible_id
      }
      formState.dynamicForm = event.questions_json;
      formState.hideFormCreateChildrenEvent = false;

      timeEvent.event_hour_default = formState.generalInfo.event_start_hour ? moment(formState.generalInfo.event_start_hour, 'HH:mm:ss') : null;
      timeEvent.event_final_hour_default = formState.generalInfo.event_end_hour ? moment(formState.generalInfo.event_end_hour, 'HH:mm:ss') : null;
      timeEvent.dateRangePickerDefaultValue = formState.generalInfo.event_start_date && formState.generalInfo.event_end_date
        ? [moment(formState.generalInfo.event_start_date), moment(formState.generalInfo.event_end_date)]
        : []
    }
    const date = new Date();
    const today =
      date.getFullYear() + '-' + (date.getMonth() + 1 < 10 ? '0' : '') + (date.getMonth() + 1) + '-' +date.getDate();

    return {
        rules,
        formState,
        timeEvent,
        onChangeRangeDatePicker,
        locale,
        handleChangeTitle,
        getValidationForms,
        formParent,
        childrenEvents,
        sendForm,
        sendValuesToValidate,
        formRef,
        addNewChildEvent,
        showFormByEmit,
        sendStatusValidationForm,
        disabledDate,
        deleteSubEvent,
        editSubEvent,
        handleDeleteSubEvent,
        handleCancelDelete,
        today
    };
  },
};

export default ChildEvent;

</script>
<style scoped lang="sass">
.card
  background-color: #fff !important
  padding: 1rem
  border-top: 0.5px solid #bdbdbd

.saved-sedes
  margin-bottom: 1.5rem

.form-font
  font-family: Nutmeg
  &.title
    font-weight: bold
    padding: 0
.btn-fill-add
  border: 1px solid #e70e4c
  color: #ffffff
  padding: 5px 15px 5px 15px
  background: #e70e4c
  border-radius: 24px
  margin: 0
.new-sede
  background: #3FA7E1
  border: 1px solid #3FA7E1
  margin: 0 1rem 0 0

button:hover
  cursor: pointer
.btn-outlined-exit
  border: 1px solid #636668
  color: #636668
  padding: 5px 15px 5px 15px
  background: none
  border-radius: 24px
  margin: 0 1rem
.card-sedes
  background-color: #ffff
  box-shadow: 0px 3px 6px #00000029
  border-radius: 20px
  padding: 1.5rem
  margin: 1rem
.btn-outlined-add
  border: 1px solid #e70e4c
  color: #e70e4c
  padding: 5px 15px 5px 15px
  background: none
  border-radius: 7px
.bt-option-card
  width: 100%
  color: #212529
  background: none
  border: none
  padding-left: 15px !important
  padding: 5px
  font-size: 12px
  margin-bottom: 0.5rem
.bt-option-card:hover
  cursor: pointer
  background: #f1efef
.btn-not-border
  border: none !important
</style>
